import React from "react"

import Background from "../components/background"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/bootstrap/container"
import PageTitle from "../components/page-title"
import GridLines from "../components/gridlines"
import FormCheck from "../components/form-check"
import Row from "../components/bootstrap/row"
import PartnerCard from "../components/partner-card"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import ImgCipherLabLogo from "../components/img-cipherlab-logo"

const RegisterPage = () => (
  <Background>
    <Layout>
      <SEO title="Registration" />
      <Container>
        <PageTitle text="Registration" />

        <Row>
          <div className="col-md-6">
            <p style={{
              textTransform: `uppercase`,
              color: `#fff`,
              fontWeight: `bold`,
              textAlign: `center`,
              maxWidth: `850px`,
              margin: `auto`,
              marginBottom: `15px`
            }}>To register for Smarter Working Live Virtual Conference and Awards, please complete the booking form below and further details will be sent on how to join the event.</p>

            <p style={{
              textTransform: `uppercase`,
              color: `#fff`,
              fontWeight: `bold`,
              textAlign: `center`,
              maxWidth: `850px`,
              margin: `auto`,
              marginBottom: `50px`
          }}>This is a free-to-attend virtual event for the Public Sector.</p>
          </div>
          <div className="col-6 col-md-3">
            <PartnerCard title="Registration Partner" width="12rem" style={{ marginLeft: `1rem`, marginBottom: `4rem` }}>
              <OutboundLink href="https://www.cipherlab.com" target="_blank" rel="noopener noreferrer"><div style={{padding: `1.5rem`}}><ImgCipherLabLogo /></div></OutboundLink>
            </PartnerCard>
          </div>
          <div className="col-6 col-md-3">
            <PartnerCard title="Already Registered?" width="12rem" style={{marginLeft: `1rem`, marginBottom: `4rem` }}>
              <div style={{
                padding: `40px`,
                textAlign: `center`
              }}>
                <OutboundLink href="https://login.swapcard.com" target="_blank" rel="noopener noreferrer" style={{
                  backgroundColor: `rgb(255, 255, 255)`,
                  borderColor: `rgb(255, 255, 255)`,
                  color: `rgb(210, 52, 100)`,
                  textTransform: `uppercase`,
                  padding: `0.6rem 1rem`,
                  fontWeight: `600`,
                }}>LOGIN</OutboundLink>
              </div>
            </PartnerCard>
          </div>
        </Row>


        <GridLines gridColor="#d23464">
          <div style={{
            fontFamily: `Din Pro`,
            // fontWeight: `400`,
            // fontSize: `1.5rem`,
            // textAlign: `center`,
            color: `#fff`,
            background: `linear-gradient(90deg, rgba(48,196,191,1) 0%, rgba(180,1,180,1) 100%)`,
            padding: `2rem`,
            margin: `0`
          }}>
            <form name="registration" action="/register-thankyou" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
              <input type="hidden" name="form-name" value="registration" />
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    {/* <label for="FirstName">First Name</label> */}
                    <input type="text" className="form-control" id="FirstName" name="first_name" placeholder="First Name" required />
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    {/* <label for="LastName">Last Name</label> */}
                    <input type="text" className="form-control" id="LastName" name="last_name" placeholder="Last Name" required />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    {/* <label for="Organisation">Organisation</label> */}
                    <input type="text" className="form-control" id="Organisation" name="organisation" placeholder="Organisation" required />
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    {/* <label for="JobTitle">Job Title</label> */}
                    <input type="text" className="form-control" id="JobTitle" name="job_title" placeholder="Job Title" required />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    {/* <label for="Phone">Phone</label> */}
                    <input type="text" className="form-control" id="Phone" name="phone" placeholder="Phone" required />
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    {/* <label for="Email">Email</label> */}
                    <input type="text" className="form-control" id="Email" name="email" placeholder="Email" required />
                  </div>
                </div>
              </div>
              <p><strong>I would like to attend (select below):</strong></p>
              <FormCheck />
              <br />
              <p>Please ensure this form is completed for each individual wishing to attend Smarter Working Live 2020.</p>
              <button type="submit" className="btn btn-primary" style={{
                backgroundColor: `rgb(255, 255, 255)`,
                borderColor: `rgb(255, 255, 255)`,
                color: `rgb(210, 52, 100)`,
                textTransform: `uppercase`,
                borderRadius: 0,
                padding: `0.6rem 1rem`,
                fontWeight: 600,
              }}>Submit</button>
            </form>
          </div>
        </GridLines>
        <br /><br /><br /><br /><br />
      </Container>
    </Layout>
  </Background>
)

export default RegisterPage
